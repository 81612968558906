@charset "utf-8";
// Core variables and mixins
@import "base/variables.scss";
@import "base/mixins.scss";

// Custom colors
$primary: #d4a462 !default; // #d4a462 = Gelbton
$secondary: #1e346e !default; // #1e346e = Blauton
$white: #fff !default;
$black: #000 !default;

// Custom Images
$header-image: "../img/header.png";
$contact-image: "../img/anmeldung.png";

// Global CSS
@import "base/page.scss";

// Components
@import "components/buttons.scss";
@import "components/navbar.scss";
@import "components/client-scroll.scss";

// Layout
@import "layout/masthead.scss";
@import "layout/services.scss";
@import "layout/portfolio.scss";
@import "layout/timeline.scss";
@import "layout/team.scss";
@import "layout/anmeldung.scss";
@import "layout/video.scss";
@import "layout/footer.scss";
